import React, { Component } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingScreen from "react-loading-screen";
import Clock from 'react-live-clock';

import loader from "../../Resources/loader.gif";

class NavBar extends Component {
  state = {
    token: null,
    loading: false,
  };

  logOut = () => {
    Swal.fire({
      title: "Do you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("role");

        this.setState({
          loading: true,
        });
    
        if (this.state.token) {
          var config = {
            method: "get",
            url: process.env.REACT_APP_BASE_URL + "/logout",
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config).then((res) => {
           
            if (res.data.success===true) {
              this.setState({
                loading: false,
              });
              window.location.href = "/";
              this.setState({
                token: null,
              });
            }else{
              this.setState({
                token: null,
              });
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!'
              })
            }
          });
        }else{
          window.location.href = "/";
        }
      }
    });
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    
  }

  render() {
    if (window.location.pathname === "/") {
      return null;
    }
   
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="header">
        		<div class="header-left">
          
			<div class="menu-icon dw dw-menu"></div>
      </div>

          <div class="header-right ml-auto">
          <button onClick={()=>window.location.pathname="pos"}  class="btn btn-warning" >POS</button>
            <div class="user-info-dropdown">
              
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                >
               
                  <span class="user-name"><Clock 
                  date={new Date()}
                  format={'YYYY-MMMM-Do, h:mm:ss A'} ticking={true} timezone={'Asia/Colombo'} />    <span class="user-icon">
                    <i class="dw dw-settings2"></i>
                   
                  </span>User Settings</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                  {/* <a class="dropdown-item" href="profile.html">
                  <i class="dw dw-user1"></i> Profile
                </a>
                <a class="dropdown-item" href="profile.html">
                  <i class="dw dw-settings2"></i> Setting
                </a> */}
                  <a
                    class="dropdown-item"
                    href="/changepassword"
                  >
                    <i class="dw dw-user"></i> Change Password
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      this.logOut();
                    }}
                  >
                    <i class="dw dw-logout"></i> Log Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        	<div class="mobile-menu-overlay"></div>
      </LoadingScreen>
    );
  }
}

export default NavBar;
