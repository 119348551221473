import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";

class changePassword extends Component {
  state = {
    token: null,
    loading: false,
    vtypes: null,
  };


  changePassword = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("old_password", e.target.old_password.value);
      data.append("new_password", e.target.new_password.value);
      data.append("confirm_password", e.target.confirm_password.value);


      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/changepassword",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

    await axios(config)
        .then( (response) => {
          if (response.data.success === true) {
            this.setState({ loading: false });
            document.getElementById("changepasswordform").reset();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Password changed!",
              showConfirmButton: false,
              timer: 1500,
            });
          }else{
            document.getElementById("changepasswordform").reset();
            this.setState({ loading: false });
            Swal.fire("Oops...", response.data.message, "error");
          }
         
        })
        .catch( (error) =>{
          this.setState({ loading: false });
          Swal.fire("Oops...", error.message, "error");
        });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));

  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left">
                <h1 class="text-center text-blue h2">Change Password</h1>
              </div>
            </div>
            <hr />
            <form onSubmit={(e) => this.changePassword(e)} id="changepasswordform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Old Password:
                </label>
                <div class="col-sm-12 col-md-10">
                <input
                    class="form-control"
                    type="password"
                    placeholder="Old Password"
                    name="old_password"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                New Password:
                </label>
                <div class="col-sm-12 col-md-10">
                <input
                    class="form-control"
                    type="password"
                    placeholder="New Password"
                    name="new_password"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                Confirm New Password:
                </label>
                <div class="col-sm-12 col-md-10">
                <input
                    class="form-control"
                    type="password"
                    placeholder="Confirm New Password"
                    name="confirm_password"
                    required
                  />
                </div>
              </div>
           
              
              <div class="form-group row">
                <button class="btn btn-primary btn-lg btn-block" type="submit">
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default changePassword;
