import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";
import AsyncSelect from "react-select/async";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
class ViewEditProduct extends Component {
  state = {
    token: null,
    loading: false,
    pid: this.props.match.params.id,
    units: null,
    // catid: null,
    subcats: null,
    product: null,
    selectedUnit: null,
    productStatus: null,
    selectedProductcatid:null,
    selectedProduct:null
  };

  selectUnit = (e) => {
    this.setState({
      selectedUnit: e.target.value,
    });
  };

  getUnits = async () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/unit",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.units[0]) {
              this.setState({
                units: res.data.units,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No Units found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load Units!",
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to Load Units!",
          });
        });
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  loadCategories = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/category/search/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.category;
            callback(json.map((i) => ({ label: i.catname, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  getSubCats = async (catid) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/subcategory/related/" + catid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.SubCategories[0]) {
              this.setState({
                subcats: res.data.SubCategories,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No Sub Categories found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Sub Categories!",
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to Sub Categories!",
          });
        });
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  getProduct = async () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL + "/product/single/" + this.state.pid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
           
            if (response.data.product[0].status === 1) {
                this.setState({
                    productStatus: true,
                })
            } else {
                this.setState({
                    productStatus: false,
                })
            }
            this.setState({
              product: response.data.product[0],
              selectedUnit: response.data.product[0].unitid,
              selectedProductcatid: response.data.product[0].catid,
              selectedProduct: response.data.product[0].catname,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  updateProduct = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      let data = {
        "id": this.state.pid,
        "catid": this.state.selectedProductcatid,
        "subcatid": e.target.subcatid.value,
        "name": e.target.name.value,
        "price": e.target.price.value,
        "sku": e.target.sku.value,
        "minqty": e.target.minqty.value,
        "unitid": e.target.unitid.value,
        "status": this.state.productStatus,
      };
      var config = {
        method: "PUT",
        url: process.env.REACT_APP_BASE_URL + "/product",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: qs.stringify(data),
      };

      axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({ loading: false });
            document.getElementById("productform").reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Product Updated",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getProduct();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured!", "error");
        });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getProduct();
    this.getUnits();
  }

  render() {
    let units = this.state.units ? (
      this.state.units.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.unit}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    let subcats = this.state.subcats ? (
      this.state.subcats.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.subcatname}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left">
                <h1 class="text-center text-blue h2">View/Edit Product</h1>
              </div>
            </div>
            <hr />
            <form onSubmit={(e) => this.updateProduct(e)} id="productform">
              {/* <input type="hidden" name="id" value={this.state.pid} /> */}
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Select Main Category:
                </label>
                <div class="col-sm-12 col-md-10">
                  <AsyncSelect
                    onChange={(opt) => {
                      if (opt) {
                        this.setState({ 
                          selectedProductcatid: opt.value,
                          selectedProduct: opt.label,
                         });
                        this.getSubCats(opt.value);
                      } else {
                        this.setState({ 
                          catid:this.state.product?.catid
                         });
                      }
                    }}
                    placeholder={"Search category"}
                    loadOptions={this.loadCategories}
                    value={{
                      value: this.state.selectedProductcatid,
                      label: this.state.selectedProduct,
                    }}
                    isClearable
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Select Sub Category:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="subcatid" class="form-control" required>
                    <option value={this.state.product?.subcatid} selected>
                      {this.state.product?.subcatname}{" "}
                    </option>
                    {subcats ? subcats : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Product Name:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    defaultValue={this.state.product?.name}
                    name="name"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  SKU:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    defaultValue={this.state.product?.sku}
                    name="sku"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Product Price:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="number"
                    defaultValue={this.state.product?.price}
                    name="price"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Units</label>
                <div class="col-sm-12 col-md-10">
                  <select
                    value={this.state.selectedUnit}
                    onChange={this.selectUnit}
                    name="unitid"
                    class="form-control"
                    required
                  >
                    <option value="">Select</option>
                    {units ? units : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Minimum Quantity
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="number"
                    defaultValue={this.state.product?.minqty}
                    name="minqty"
                    required
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Product Status
                </label>
                <div class="col-sm-12 ">
                  <BootstrapSwitchButton
                    checked={this.state.productStatus}
                    onlabel="Active"
                    offlabel="Deactive"
                    width={100}
                    onstyle="primary"
                    offstyle="danger"
                    onChange={(checked: boolean) => {
                      this.setState({ productStatus: checked });
                    }}
                  />
                </div>
              </div>

              <div class="form-group row">
                <button class="btn btn-primary btn-lg btn-block" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default ViewEditProduct;
