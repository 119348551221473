import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";
import AsyncSelect from "react-select/async";

class AddProduct extends Component {
    state = {
      token: null,
      loading: false,
      units: null,
      catid:null,
      subcats: null
    };
  
    loadCategories = async (inputText, callback) => {
        if (this.state.token) {
          var config = {
            method: "get",
            url: process.env.REACT_APP_BASE_URL + "/category/search/" + inputText,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };
    
          await axios(config)
            .then((res) => {
              if (res.data.success) {
                const json = res.data.category;
                callback(json.map((i) => ({ label: i.catname, value: i.id })));
              }
            })
            .catch((error) => {});
        } else {
          this.setState({
            loading: false,
          });
    
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Authentication Error!",
          });
        }
      };
  
    addProduct = (e) => {
      e.preventDefault();
      this.setState({
        loading: true,
      });
  
      if (this.state.token) {
        let data = new FormData();
     
        data.append("catid", this.state.catid);
        data.append("subcatid", e.target.subcatid.value);
        data.append("name", e.target.name.value);
        data.append("price", e.target.price.value);
        data.append("unitid", e.target.unitid.value);
        data.append("sku", e.target.sku.value);
        data.append("minqty", e.target.minqty.value);

  
        var config = {
          method: "post",
          url: process.env.REACT_APP_BASE_URL + "/product",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data,
        };
  
        axios(config)
          .then( (response) => {
            if (response.data.success === true) {
              this.setState({ loading: false });
              document.getElementById("productform").reset();
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Product Added",
                showConfirmButton: false,
                timer: 1500,
              });
            }
           
          })
          .catch( (error) =>{
            this.setState({ loading: false });
            Swal.fire("Oops...", "Error occured", "error");
          });
      }
    };

    getUnits = async () => {
      if (this.state.token) {
        var config = {
          method: "get",
          url: process.env.REACT_APP_BASE_URL + "/unit",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };
  
        await axios(config)
          .then((res) => {
            if (res.data.success) {
              if (res.data.units[0]) {
                this.setState({
                  units: res.data.units,
                  loading: false,
                });
              } else {
                this.setState({
                  loading: false,
                });
  
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "No Units found!",
                });
              }
            } else {
              this.setState({
                loading: false,
              });
  
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to Load Units!",
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
  
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load Units!",
            });
          });
      } else {
        this.setState({
          loading: false,
        });
  
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Authentication Error!",
        });
      }
    };

    getSubCats = async (catid) => {
      if (this.state.token) {
        var config = {
          method: "get",
          url: process.env.REACT_APP_BASE_URL + "/subcategory/related/" + catid,
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };
  
        await axios(config)
          .then((res) => {
            if (res.data.success) {
              if (res.data.subcategories[0]) {
                this.setState({
                  subcats: res.data.subcategories,
                  loading: false,
                });
              } else {
                this.setState({
                  loading: false,
                });
  
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "No Sub Categories found!",
                });
              }
            } else {
              this.setState({
                loading: false,
              });
  
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to Sub Categories!",
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
  
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Sub Categories!",
            });
          });
      } else {
        this.setState({
          loading: false,
        });
  
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Authentication Error!",
        });
      }
    };
  
  
  
    componentDidMount() {
      this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
      this.getUnits()
    }
  
    render() {
      let units = this.state.units ? (
        this.state.units.map((data, i) => {
          return (
            <option value={data.id} key={i}>
              {data.unit}
            </option>
          );
        })
      ) : (
        <option>No Data</option>
      );
      let subcats = this.state.subcats ? (
        this.state.subcats.map((data, i) => {
          return (
            <option value={data.id} key={i}>
              {data.subcatname}
            </option>
          );
        })
      ) : (
        <option value="3">No Data</option>
      );
      return (
        <LoadingScreen
          loading={this.state.loading}
          bgColor="#ffffffcc"
          logoSrc={loader}
        >
          <div class="main-container">
            <div class="pd-20 card-box mb-30">
              <div class="clearfix">
                <div class="pull-left">
                  <h1 class="text-center text-blue h2">Add a Product</h1>
                </div>
              </div>
              <hr />
              <form onSubmit={(e) => this.addProduct(e)} id="productform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Select Main Category:
                </label>
                <div class="col-sm-12 col-md-10">
                  <AsyncSelect
                    onChange={(opt) => {
                      if (opt) {
                        this.setState({ catid: opt.value });
                        this.getSubCats(opt.value)
                      } else {
                        this.setState({ catid: null });
                      }
                    }}
                    placeholder={"Search category"}
                    loadOptions={this.loadCategories}
                    isClearable
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Select Sub Category:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="subcatid" class="form-control" required>
                    <option value="">Select</option>
                    {subcats ? subcats : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
                <div class="form-group row">
                  <label class="col-sm-12 col-md-2 col-form-label">
                  Product Name:
                  </label>
                  <div class="col-sm-12 col-md-10">
                  <input
                      class="form-control"
                      type="text"
                      placeholder="Product Name"
                      name="name"
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-12 col-md-2 col-form-label">
                  SKU:
                  </label>
                  <div class="col-sm-12 col-md-10">
                  <input
                      class="form-control"
                      type="text"
                      placeholder="SKU"
                      name="sku"
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-12 col-md-2 col-form-label">
                 Product Price:
                  </label>
                  <div class="col-sm-12 col-md-10">
                  <input
                      class="form-control"
                      type="number"
                      placeholder="Product Price"
                      name="price"
                      required
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-12 col-md-2 col-form-label">
                    Units
                  </label>
                  <div class="col-sm-12 col-md-10">
                  <select name="unitid" class="form-control" required>
                    <option value="">Select</option>
                    {units ? units : <option value="">No Data</option>}
                  </select>
                </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-12 col-md-2 col-form-label">
                  Minimum Quantity
                  </label>
                  <div class="col-sm-12 col-md-10">
                    <input
                      class="form-control"
                      type="number"
                      placeholder="Minimum Quantity"
                      name="minqty"
                      required
                    />
                  </div>
                </div>
           
              
       
                <div class="form-group row">
                  <button class="btn btn-primary btn-lg btn-block" type="submit">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </LoadingScreen>
      );
    }
  }
  
  export default AddProduct;
  