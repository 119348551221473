import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";

import loader from "../../Resources/loader.gif";

class NewStore extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      status: null,
      loading: false,
    };
  }

  add = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      let data = new FormData();
      data.append("name", e.target.name.value);
      data.append("address", e.target.address.value);
      data.append("brnum", e.target.brnum.value);
      data.append("taxid", e.target.taxid.value);
      data.append("phone1", e.target.phone1.value);
      data.append("phone2", e.target.phone2.value);
      data.append("status", "1");

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/store/new",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          if ((response.data.success)===true) {
            this.setState({ loading: false });
            document.getElementById("projectform").reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Store Added",
              showConfirmButton: false,
              timer: 1500,
            });
           
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured", "error");
        });
    }
  };

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
  };

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
            <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left">
                <h1 class="text-center text-blue h2">New Store</h1>
              </div>
            </div>
            <hr />
        
              
              <form
              
                onSubmit={(e) => this.add(e)}
                id="projectform"
              >
                <div className="form-group">
                  <label htmlFor="custName">Store Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    placeholder="Store Name"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    required
                    placeholder="Address"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone1">Contact Number</label>
                  <input
                    type="number"
                    className="form-control"
                    name="phone1"
                    required
                    placeholder="Contact Number"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone2">Secondary Contact Number</label>
                  <input
                    type="number"
                    className="form-control"
                    name="phone2"
                    placeholder="Secondary Contact Number"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone2">Business Registration</label>
                  <input
                    type="text"
                    className="form-control"
                    name="brnum"
                    placeholder="Business Registration"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone2">Tax Registration</label>
                  <input
                    type="text"
                    className="form-control"
                    name="taxid"
                    placeholder="Tax Registration"
                  />
                </div>

                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <button type="reset" className="btn btn-light">
                  Cancel
                </button>
              </form>
            </div>
          </div>
 
      </LoadingScreen>
    );
  }
}

export default NewStore;
