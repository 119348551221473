import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";

class AllProductsSold extends Component {
  state = {
    token: null,
    loading: false,
    products: null,
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getProducts();
  }

  getProducts = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/reports/soldproducts",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.products[0]) {
              this.setState({
                loading: false,
                products: res.data.products,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No products found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load products!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    var products = this.state.products ? (
        this.state.products.map((data, i) => {
         
          return (
            <tr>
              
              <td> {data.name}</td>
              <td> {data.count}</td>
          
            </tr>
          );
        })
      ) : (
        <tr>No Data</tr>
      );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left d-flex w-100">
                <h1 class="text-center text-blue h2">Sold Products List</h1>
               
              </div>
            </div>
          </div>
          <div class="pd-20 card-box mb-30">
          <table class="table table-hover">
                <thead class="thead-dark"> 
                  <th>Product</th>
                  <th>Items Sold</th>
                </thead>
                <tbody>
                {products ? products : <tr value="">No Data</tr>}
                </tbody>
              </table>
          </div>
        </div>
      
      </LoadingScreen>
    );
  }
}

export default AllProductsSold;