import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";

import loader from "../Resources/loader.gif";

class Dashboard extends Component {
  state = {
    token: null,
    loading: false,
    counts: null,
    role:null
  };

  getRole = async () => {
    this.setState({
      loading: true,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/me",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
            
            sessionStorage.setItem(
              "role",
              JSON.stringify(response.data.role)
            );
            this.setState({
              role: response.data.role,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getCounts = async () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/dashboard/counts",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data);
            this.setState({
              counts: res.data,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to counters!",
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to Load counters!",
          });
        });
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };


  async  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getRole();
    this.getCounts();

  }
  
  render() {
    var products = this.state.counts?.top10products ? (
      this.state.counts?.top10products.map((data, i) => {
        return (
          <tr>
            <td> {data.name}</td>
            <td> {data.count}</td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    console.log(this.state.role);
    // console.log(this.state.role === 'admin');
    if (this.state.role === 'admin') {
      return (
        <div class="main-container">
          <div class="pd-ltr-20">
            <div class="row">
               <div class="col">
                <div class="card-counter primary">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.dailysales || 0}
                  </span>
                  <span class="count-name">Daily Total Sales</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter primary">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.monthlysales}
                  </span>
                  <span class="count-name">This month sales</span>
                </div>
              </div>
  
              <div class="col">
                <div class="card-counter warning">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.totalsales}
                  </span>
                  <span class="count-name">Life Time Sales</span>
                </div>
              </div> 
            </div>
             <div class="row">
              <div class="col">
                <div class="card-counter info">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.grndailyprofit}
                  </span>
                  <span class="count-name">GRN Items Today's Profit</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter info">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.grnmonthlyprofit}
                  </span>
                  <span class="count-name">GRN Items This Month Profit</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter info">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.grntotalprofit}
                  </span>
                  <span class="count-name">GRN Items Profit Lifetime</span>
                </div>
              </div>
            </div>

            <div class="row">
            <div class="col">
                <div class="card-counter primary">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.dailysales || 0}
                  </span>
                  <span class="count-name">Daily Total Sales</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter danger">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.totalcreditamont}
                  </span>
                  <span class="count-name">Total Credit</span>
                </div>
              </div>
  
              <div class="col">
                <div class="card-counter success">
                  <i class="fa fa-user"></i>
                  <span class="count-numbers">
                    {this.state.counts?.totalcustomers}
                  </span>
                  <span class="count-name">Total Customers</span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row mt-5">
            <div class="col">
              <div class="card ">
                <div class="card-header bg-primary text-white   ">
                  Top 10 Sold Products
                </div>
  
                <table class="table table-hover">
                  <thead class="thead-dark">
                    <th>Product</th>
                    <th>Items Sold</th>
                  </thead>
                  <tbody>{products ? products : <tr value="">No Data</tr>}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else{
      return (
        <div class="main-container">
          <div class="pd-ltr-20">
            <div class="row">
              {/* <div class="col">
                <div class="card-counter primary">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.dailysales || 0}
                  </span>
                  <span class="count-name">Daily Total Sales</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter primary">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.monthlysales}
                  </span>
                  <span class="count-name">This month sales</span>
                </div>
              </div>
  
              <div class="col">
                <div class="card-counter warning">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.totalsales}
                  </span>
                  <span class="count-name">Life Time Sales</span>
                </div>
              </div> */}
            </div>
            {/* <div class="row">
              <div class="col">
                <div class="card-counter info">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.grndailyprofit}
                  </span>
                  <span class="count-name">GRN Items Today's Profit</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter info">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.grnmonthlyprofit}
                  </span>
                  <span class="count-name">GRN Items This Month Profit</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter info">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.grntotalprofit}
                  </span>
                  <span class="count-name">GRN Items Profit Lifetime</span>
                </div>
              </div>
            </div>
           */}
            <div class="row">
            <div class="col">
                <div class="card-counter primary">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.dailysales || 0}
                  </span>
                  <span class="count-name">Daily Total Sales</span>
                </div>
              </div>
              <div class="col">
                <div class="card-counter danger">
                  <i class="fa fa-money"></i>
                  <span class="count-numbers">
                    Rs.{this.state.counts?.totalcreditamont}
                  </span>
                  <span class="count-name">Total Credit</span>
                </div>
              </div>
  
              <div class="col">
                <div class="card-counter success">
                  <i class="fa fa-user"></i>
                  <span class="count-numbers">
                    {this.state.counts?.totalcustomers}
                  </span>
                  <span class="count-name">Total Customers</span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row mt-5">
            <div class="col">
              <div class="card ">
                <div class="card-header bg-primary text-white   ">
                  Top 10 Sold Products
                </div>
  
                <table class="table table-hover">
                  <thead class="thead-dark">
                    <th>Product</th>
                    <th>Items Sold</th>
                  </thead>
                  <tbody>{products ? products : <tr value="">No Data</tr>}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
 
  }
}

export default Dashboard;
