import React, { Component } from "react";
import axios from "axios";
import { Model,Button } from "react-bootstrap";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";

import loader from "../../Resources/loader.gif";

class StoreList extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      stores: null,
      loading: true,
    };
  }

  getStores = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/store",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.stores[0]) {
              this.setState({
                loading: false,
                stores: res.data.stores,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No stores found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load stores!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

 

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getStores();
  }


  render() {
    var projRows =
      this.state.stores !== null &&
      this.state.stores.map((data, i) => {
        return (
          <div className="col-sm-12 col-md-4" key={i}>
            <div className="card mb-4 cust-row-card">
              <div className="row no-gutters">
                <div className="col-12">
                  <div className="card-body">
                    <h1 className="card-title">
                      {data.name ? data.name : null}
                    </h1>
                    <p className="card-text job-desc clamp">
                      {data.phone1 ? data.phone1 : null}
                    </p>
                    <p className="card-text">
                      {data.address ? data.address : null}
                    </p>
                    <div className="cust-rows-buttons">
                      <div className="row">
                        <div className="col">
                        <button onClick={()=>window.location.pathname =`/store/update/${data.id}`} class="btn btn-info">View/Edit</button>
                
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
      
        {this.state.projects !== null && (
                  <div class="main-container">
                  <div class="pd-20 card-box mb-30">
                    <div class="clearfix">
                      <div class="pull-left">
                        <h1 class="text-center text-blue h2">Store List</h1>
                      </div>
                    </div>
          <div className="grid-margin stretch-card">
           
      
                <hr />
                <div className="row">{projRows ? projRows : "No Data"}</div>
           
          </div>
          </div>
          </div>
        )}
      </LoadingScreen>
    );
  }
}

export default StoreList;
