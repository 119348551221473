import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import loader from "../../Resources/loader.gif";

class PrintInvoice extends Component {
  state = {
    token: null,
    loading: false,
    invoiceid: this.props.match.params.id,
    invoice: null,
    invoiceitems: null,
    discountstatus: null,
  };

  getInvoice = async () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL +
          "/invoice/single/" +
          this.state.invoiceid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              invoice: response.data.invoice[0],
              discountstatus: response.data.invoice[0].discountstatus,
              invoiceitems: response.data.invoiceitems,
              loading: false,
            });
            // var content = document.getElementById("printbody");
            // window.print();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getInvoice();
  }

  render() {
    var ids = this.state.invoiceitems ? (
      this.state.invoiceitems.map((data, i) => {
        return (
          <tr>
            <td class="largetxt">{data.name}</td>
            <td class="text-center largetxt">{data.unit_price}</td>
            <td class="text-center largetxt">{data.qty}</td>
            <td class="text-center largetxt">{data.discount}</td>
            <td class="text-right largetxt">
              {data.unit_price * data.qty - data.discount}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );

    let discount;

    if (this.state.discountstatus == "0") {
      discount = (
        <strong>Discount Amount : Rs. {this.state.invoice?.discount}</strong>
      );
    } else {
      discount = "";
    }

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <link
          href="//netdna.bootstrapcdn.com/bootstrap/3.1.0/css/bootstrap.min.css"
          rel="stylesheet"
          id="bootstrap-css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="/vendors/styles/invoice.css"
          media="print"
        />

        <div class="main-container">
          <body id="printbody" class="A5 landscape">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 row-bottom-margin">
                  <div class="invoice-title">
                    <h2 class="text-uppercase">DILRUK MOTORS</h2>
                    <h4 class="pull-right">SALES INVOICE</h4>
                    <span class="text-center">
                      <span class="largetxt">
                        186 Makola North, Makola, Sri Lanka
                      </span>
                      <br />
                      <span class="largetxt">
                        {" "}
                        011 2 962 300 / 077 8 900 757 / 071 2 746 303
                      </span>
                    </span>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-xs-6">
                      <address>
                        <strong>Billed To:</strong>
                        <br />
                        {this.state.invoice?.name}
                        <br />
                        {this.state.invoice?.phone1}
                      </address>
                    </div>
                    <div class="col-xs-6 text-right"></div>
                  </div>
                  <div class="row row-bottom-margin">
                    <div class="col-xs-6">
                      <address>
                        <strong>Payment Method:</strong>
                        <br />
                        <span class="largetxt">
                          {" "}
                          {this.state.invoice?.paymenttype
                            .charAt(0)
                            .toUpperCase() +
                            this.state.invoice?.paymenttype.slice(1)}
                        </span>
                      </address>
                    </div>
                    <div class="col-xs-6 text-right largetxt">
                      <strong>Invoice No: </strong>#
                      {this.state.invoice?.invoiceid}
                      <br />
                      <strong>Invoice Date: </strong>
                      {this.state.invoice?.created_at}
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <strong>Item</strong>
                          </th>
                          <th class="text-center">
                            <strong>Price</strong>
                          </th>
                          <th class="text-center">
                            <strong>Quantity</strong>
                          </th>
                          <th class="text-center">
                            <strong>Discount</strong>
                          </th>
                          <th class="text-right">
                            <strong>Totals</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{ids ? ids : <tr value="">No Data</tr>}</tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-2 largetxt">
                  .......................... <br />
                  Authorized Sign
                </div>
                <div class="col-xs-2 largetxt">
                  .......................... <br />
                  Customer's Sign
                </div>
                <div class="col-xs-8 text-right largetxt">
                  <strong>Sub Total :</strong> Rs.{" "}
                  {parseInt(this.state.invoice?.total) +
                    parseInt(this.state.invoice?.discount)}{" "}
                  <br />
                  {discount}
                  <br />
                  <strong>Total :</strong> Rs. {this.state.invoice?.total}{" "}
                  <br />
                  <strong>Balance Amount :</strong> Rs. {this.state.invoice?.total-this.state.invoice?.paidtotal}{" "}
                  <br />
                </div>
              </div>
              <p class="largetxt">
                Remarks:
                <span class="largetxt"> {this.state.invoice?.remarks}</span>
              </p>
              <p>
                {" "}
                <strong>
                  * විකුණන ලද විදුලි උපාංගමය කොටස්, ඇසුරුම් පලුදු වූ භාණ්ඩ නැවත
                  භාරගනු නොලැබේ.
                </strong>
                <br />
                <br />
                <br />
                <strong>
                  * භාණ්ඩ ආපසු භාරගැනීමේදී අදාල බිල්පත අනිවාර්ය වේ.
                </strong>
              </p>

              <span class="billfooter">
                Software Solution By iDeacurl.com | 0777 17 0101{" "}
              </span>
            </div>
          </body>
        </div>
      </LoadingScreen>
    );
  }
}

export default PrintInvoice;
