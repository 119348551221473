import React, { Component } from "react";
import axios from "axios";
import  qs from "qs";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";

class ViewEditSupplier extends Component {
  state = {
    token: null,
    loading: false,
    sid:this.props.match.params.id,
    supplier: null,
  };

 
  getSupplier = async () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/supplier/single/" + this.state.sid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
          
            this.setState({
              supplier: response.data.supplier,
              loading: false,
            });
            
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  updateSupplier = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
    
      let data={
        "id": this.state.sid,
        "name": e.target.name.value,
        "company": e.target.company.value,
        "address": e.target.address.value,
        "phone1": e.target.phone1.value,
        "phone2": e.target.phone2.value,
      }
      var config = {
        method: "PUT",
        url: process.env.REACT_APP_BASE_URL + "/supplier",
        headers: {
         
          Authorization: `Bearer ${this.state.token}`,
        },
        data: qs.stringify(data),
      };

      axios(config)
        .then( (response) => {
          if (response.data.success === true) {
            this.setState({ loading: false });
            
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Supplier Updated",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getSupplier()
          }
         
        })
        .catch( (error) =>{
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured!", "error");
        });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSupplier();
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left">
                <h1 class="text-center text-blue h2">View/Edit Driver</h1>
              </div>
            </div>
            <hr />
            <form onSubmit={(e) => this.updateSupplier(e)} id="supplierform">
            <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Name:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    defaultValue={this.state.supplier?.name}
                    name="name"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Company:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    defaultValue={this.state.supplier?.company}
                    name="company"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Address:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    defaultValue={this.state.supplier?.address}
                    name="address"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Phone 1:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    defaultValue={this.state.supplier?.phone1}
                    name="phone1"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 2:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    defaultValue={this.state.supplier?.phone2}
                    name="phone2"
                    required
                  />
                </div>
              </div>


              <div class="form-group row">
                <button class="btn btn-primary btn-lg btn-block" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default ViewEditSupplier;
