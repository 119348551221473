class Auth {
    constructor() {
      let utoken=JSON.parse(sessionStorage.getItem("userToken"));
      if(utoken!==null){
        this.authenticated = true;
      }else{
        this.authenticated = false;
      }

    }

  
    isAuthenticated() {
      return this.authenticated;
    }
  }
  
  export default new Auth();