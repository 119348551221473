import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import loader from "../Resources/loader.gif";
import { Modal, Button } from "react-bootstrap";
import Switch from "react-switch";
class POS extends Component {
  constructor() {
    super();
    this.state = {
      values: [],
      token: null,
      loading: false,
      invoiceid: null,
      productidarr: [],
      productarr: [],
      productpricearr: [],
      productdiscountearr: [],
      productqtyarr: [],
      totalamount: [],
      customers: null,
      selectedcustid: null,
      discountamount: 0,
      showdiscount: false,
      isOpenCustomer: false,
      customerlabel: null,
      isOpenFinalizeSale: false,
      ramount: 0,
      paymenttype: "cash",
      remarks: "",
      paymentreference: "",
      customerlabelname:null
    };
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
  }

  openModal = () => this.setState({ isOpenCustomer: true });
  closeModal = () => this.setState({ isOpenCustomer: false });

  handleSwitchChange(showdiscount) {
    this.setState({ showdiscount });
  }

  openModalFinalSale = () => {
    if (this.state.selectedcustid == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select a Customer!",
      });
    } else if (this.state.productarr.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Empty bill!",
      });
    } else {
      this.setState({ isOpenFinalizeSale: true });
    }
  };
  closeModalFinalSale = () => this.setState({ isOpenFinalizeSale: false });

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getCustomers();
  };

  addCustomer = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("name", e.target.name.value);
      data.append("company", e.target.company.value);
      data.append("address", e.target.address.value);
      data.append("phone1", e.target.phone1.value);
      data.append("phone2", e.target.phone2.value);
      data.append("creditdays", e.target.creditdays.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/customer",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpenCustomer: false,
            });
            document.getElementById("customerform").reset();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Customer Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getCustomers();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured", "error");
        });
    }
  };

  loadProducts = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product/search/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.products;
            callback(json.map((i) => ({ label: i.name, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };
  loadProductsbySKU = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL + "/product/searchbysku/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.products;
            callback(json.map((i) => ({ label: i.name, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  loadCustomer = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/search/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.customers;
            callback(json.map((i) => ({ label: i.phone1, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };
  
  loadCustomerName = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/searchbyname/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.customers;
            callback(json.map((i) => ({ label: i.name, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  handleCustomerChange(opt) {
    this.setState({
      customerlabel: opt.label,
      selectedcustid: opt.value,
    });
  }

  handleCustomerChangeName(opt) {
    this.setState({
      customerlabelname: opt.label,
      selectedcustid: opt.value,
    });
  }

  createUI() {
    return this.state.values.map((qty, i) => (
      <div key={i}>
        <tr class="d-flex">
          <td class="col-2">
            <h6 class="title text-truncate">
              {this.state.productarr[i] || ""}
            </h6>
          </td>
          <td class="col-2">
            <div class="price-wrap">
              <var class="price">{this.state.productpricearr[i] || ""}</var>
            </div>
          </td>
          <td class="col-3 text-center">
            <div
              class="m-btn-group m-btn-group--pill btn-group mr-2"
              role="group"
              aria-label="..."
            >
              <button
                type="button"
                onClick={() => this.handleqtyminus(i)}
                class="m-btn btn btn-secondary"
              >
                <i class="fa fa-minus"></i>
              </button>
              <p class="m-3">{this.state.productqtyarr[i] || ""}</p>

              <button
                type="button"
                onClick={() => this.handleqtyplus(i)}
                class="m-btn btn btn-secondary"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </td>
          <td class="col-2">
            <h6 class="title text-truncate">
              <input
                type="number"
                class="form-control"
                value={this.state.productdiscountearr[i]}
                onChange={(e) => this.handlediscount(i, e)}
              />
            </h6>
          </td>
          <td class="col-2">
            <h6 class="title text-truncate">
              {this.state.productpricearr[i] * this.state.productqtyarr[i] -
                this.state.productdiscountearr[i] || 0}
            </h6>
          </td>
          <td class="col-1 ">
            <a
              onClick={this.removeClick.bind(this, i)}
              class="btn text-white btn-danger"
            >
              <i class="fa fa-trash"></i>
            </a>
          </td>
        </tr>
      </div>
    ));
  }

  handleProductChange(opt) {
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL + "/product/posproduct/" + opt.value,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            this.setState((prevState) => ({
              values: [...prevState.values, ""],
            }));
            let vcount = this.state.values.length;
            let productarr = [...this.state.productarr];
            productarr[vcount - 1] = response.data.product[0].name;
            let productidarr = [...this.state.productidarr];
            productidarr[vcount - 1] = response.data.product[0].id;
            let productpricearr = [...this.state.productpricearr];
            productpricearr[vcount - 1] = parseInt(response.data.product[0].price);
            let productqtyarr = [...this.state.productqtyarr];
            productqtyarr[vcount - 1] = 1;
            let productdiscountearr = [...this.state.productdiscountearr];
            productdiscountearr[vcount - 1] = 0;
            let totalamount = [...this.state.totalamount];
            totalamount[vcount - 1] = parseInt(response.data.product[0].price);
            this.setState({
              productarr,
              productpricearr,
              productqtyarr,
              totalamount,
              productidarr,
              productdiscountearr,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleqtyplus = (i) => {
    let productqtyarr = [...this.state.productqtyarr];
    productqtyarr[i] = productqtyarr[i] + 1;
    let totalamount = [...this.state.totalamount];
    totalamount[i] = this.state.productpricearr[i] * productqtyarr[i];
    this.setState({
      productqtyarr,
      totalamount,
    });
  };

  handlediscount = (i, event) => {
    let productdiscountearr = [...this.state.productdiscountearr];
    productdiscountearr[i] = parseInt(event.target.value);
    this.setState({
      productdiscountearr,
    });
  };

  handleqtyminus(i) {
    if (this.state.productqtyarr[i] != 1) {
      let productqtyarr = [...this.state.productqtyarr];
      productqtyarr[i] = productqtyarr[i] - 1;
      let totalamount = [...this.state.totalamount];
      totalamount[i] = this.state.productpricearr[i] * productqtyarr[i];
      this.setState({
        productqtyarr,
        totalamount,
      });
    }
  }

  removeClick(i) {
    let values = [...this.state.values];
    let productqtyarr = [...this.state.productqtyarr];
    let totalamount = [...this.state.totalamount];
    let productarr = [...this.state.productarr];
    let productdiscountearr = [...this.state.productdiscountearr];
    let productpricearr = [...this.state.productpricearr];
    let productidarr = [...this.state.productidarr];
    values.splice(i, 1);
    productqtyarr.splice(i, 1);
    totalamount.splice(i, 1);
    productarr.splice(i, 1);
    productdiscountearr.splice(i, 1);
    productpricearr.splice(i, 1);
    productidarr.splice(i, 1);
    this.setState({
      values,
      productqtyarr,
      totalamount,
      productarr,
      productdiscountearr,
      productpricearr,
      productidarr
    });
  }

  getCustomers = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.customers[0]) {
              this.setState({
                loading: false,
                customers: res.data.customers,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No customers found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load customers!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  addinvoiceitem=async(data)=>{
    var config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL + "/invoiceitem",
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
       return(JSON.stringify(response.data));
      })
      .catch((error) => {
        return(error);
      });
  }

  savenPrint = async () => {
    if (this.state.selectedcustid == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select a Customer!",
      });
    } else if (this.state.productarr.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Empty bill!",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({
            loading: true,
          });

          var data = new FormData();
          data.append("customerid", this.state.selectedcustid);
          data.append(
            "total",
            (this.state.totalamount.reduce((a, b) => a + b, 0)) -
                            (parseInt(this.state.discountamount) + (
                              this.state.productdiscountearr.reduce(
                                (a, b) => a + b,
                                0
                              )))
          );
          data.append("discount", (parseInt(this.state.discountamount) + (
            this.state.productdiscountearr.reduce(
              (a, b) => a + b,
              0
            ))));
          data.append("remarks", this.state.remarks);
          data.append("paymenttype", this.state.paymenttype);
          if (this.state.showdiscount === true) {
            data.append("discountstatus", "1");
          }
          if (this.state.showdiscount === false) {
            data.append("discountstatus", "0");
          }

          var config = {
            method: "post",
            url: process.env.REACT_APP_BASE_URL + "/invoice",
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
            data: data,
          };

          axios(config)
            .then((response) => {
              if (response.data.success === true) {
                this.setState({
                  invoiceid: response.data.id,
                });

                for (let index = 0; index < this.state.values.length; index++) {
                  var data = new FormData();
                  data.append("invoiceid", this.state.invoiceid);
                  data.append("pid", this.state.productidarr[index]);
                  data.append("unit_price", this.state.productpricearr[index]);
                  data.append("qty", this.state.productqtyarr[index]);
                  data.append(
                    "discount",
                    this.state.productdiscountearr[index]
                  );
                  
                  this.addinvoiceitem(data);
                
                }
                let iid = this.state.invoiceid;
                if (iid && this.state.paymenttype != "credit") {
                  var data = new FormData();

                  data.append("invoiceid", iid);
                  data.append("paymenttype", this.state.paymenttype);
                  data.append("reference", this.state.paymentreference);
                  let totalinvoice=  (this.state.totalamount.reduce((a, b) => a + b, 0)) -
                    (parseInt(this.state.discountamount) + (
                      this.state.productdiscountearr.reduce(
                        (a, b) => a + b,
                        0
                      )))
                  data.append("amount", totalinvoice);
                  data.append("billnum", "0");

                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_BASE_URL + "/payment",
                    headers: {
                      Authorization: `Bearer ${this.state.token}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then((response) => {
                      if (response.data.success === true) {
                        this.setState({
                          loading: false,
                          values: [],
                          productarr: [],
                          productqtyarr: [],
                          totalamount: [],
                          productlabel: [],
                          productpricearr: [],
                          productdiscountearr:[],
                          productidarr:[],
                          invoiceid: null,
                          discountamount: 0,
                        });
                        Swal.fire({
                          position: "top-end",
                          icon: "success",
                          title: "Added",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        window.location.replace("/printinvoice/" + iid);
                      }
                    })
                    .catch((error) => {
                      this.setState({ loading: false });
                      Swal.fire("Oops...", "Error occured", "error");
                    });
                }else{
                  var data = new FormData();

                  data.append("invoiceid", iid);
                  data.append("paymenttype", 'cash');
                  data.append("reference", this.state.paymentreference);
                  data.append("amount", this.state.ramount);
                  data.append("billnum", "0");

                  var config = {
                    method: "post",
                    url: process.env.REACT_APP_BASE_URL + "/payment",
                    headers: {
                      Authorization: `Bearer ${this.state.token}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then((response) => {
                      if (response.data.success === true) {
                        this.setState({
                          loading: false,
                          values: [],
                          productarr: [],
                          productqtyarr: [],
                          totalamount: [],
                          productlabel: [],
                          productpricearr: [],
                          productdiscountearr:[],
                          productidarr:[],
                          invoiceid: null,
                          discountamount: 0,
                        });
                        Swal.fire({
                          position: "top-end",
                          icon: "success",
                          title: "Added",
                          showConfirmButton: false,
                          timer: 1500,
                        });
                        window.location.replace("/printinvoice/" + iid);
                      }
                    })
                    .catch((error) => {
                      this.setState({ loading: false });
                      Swal.fire("Oops...", "Error occured", "error");
                    });
                  // this.setState({
                  //   loading: false,
                  //   values: [],
                  //   productarr: [],
                  //   productqtyarr: [],
                  //   totalamount: [],
                  //   productlabel: [],
                  //   productpricearr: [],
                  //   productdiscountearr:[],
                  //   invoiceid: null,
                  //   discountamount: 0,
                  // });
                  // Swal.fire({
                  //   position: "top-end",
                  //   icon: "success",
                  //   title: "Added",
                  //   showConfirmButton: false,
                  //   timer: 1500,
                  // });
                  // window.location.replace("/printinvoice/" + iid);
                }
              }
            })
            .catch((error) => {
              this.setState({ loading: false });
              Swal.fire("Oops...", "Error occured", "error");
            });
  
        }
      });
    }
  };

  render() {
    // let customerselect = this.state.customers ? (
    //   this.state.customers.map((data, i) => {
    //     return (
    //       <option value={data.id} key={i}>
    //         {data.name}
    //       </option>
    //     );
    //   })
    // ) : (
    //   <option>No Data</option>
    // );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <section class="section-content padding-y-sm bg-default">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="row p-3">
                      <div class="col-md-3">
                        <AsyncSelect
                          placeholder={"Customer Name"}
                          onChange={(opt) => {
                            if (opt) {
                              this.handleCustomerChangeName(opt);
                            }
                          }}
                          value={{
                            value: this.state.selectedcustid,
                            label: this.state.customerlabelname,
                          }}
                          loadOptions={this.loadCustomerName}
                          isClearable
                          required
                        />
                      </div>
                      <div class="col-md-3">
                        <AsyncSelect
                          placeholder={"Customer"}
                          onChange={(opt) => {
                            if (opt) {
                              this.handleCustomerChange(opt);
                            }
                          }}
                          value={{
                            value: this.state.selectedcustid,
                            label: this.state.customerlabel,
                          }}
                          loadOptions={this.loadCustomer}
                          isClearable
                          required
                        />
                      </div>
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="btn btn-warning"
                          onClick={this.openModal}
                        >
                          Add
                        </button>
                      </div>
                      <div class="col-md-2">
                        <AsyncSelect
                          placeholder={"Search By SKU"}
                          onChange={(opt) => {
                            if (opt) {
                              this.handleProductChange(opt);
                            }
                          }}
                          loadOptions={this.loadProductsbySKU}
                          isClearable
                          required
                        />
                      </div>
                      <div class="col-md-3">
                        <AsyncSelect
                          placeholder={"Search Products"}
                          onChange={(opt) => {
                            if (opt) {
                              this.handleProductChange(opt);
                            }
                          }}
                          loadOptions={this.loadProducts}
                          isClearable
                          required
                        />
                      </div>
                    </div>
                    <table class="table table-hover">
                      <thead class="thead table-primary">
                        <tr class="d-flex">
                          <th class="col-2">Item</th>
                          <th class="col-2">Price</th>
                          <th class="col-3">Quantity</th>
                          <th class="col-2">Discount</th>
                          <th class="col-2">Total Amount</th>
                          <th class="col-1">Delete</th>
                        </tr>
                      </thead>
                      <tbody>{this.createUI()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <div class="card text-right p-5">
                    <div class="box">
                      <dl class="dlist-align">
                        <dt>Total Discount:</dt>
                        <dd class="text-right">
                          <input
                            type="number"
                            onChange={(e) => {
                              this.setState({ discountamount: e.target.value });
                            }}
                            placeholder="Discount in amount"
                            class="form-control offset-md-10 col-md-2"
                          />
                        </dd>
                        <dd class="text-right">
                          <input
                            type="number"
                            onChange={(e) => {
                              this.setState({
                                discountamount:
                                  (this.state.totalamount.reduce(
                                    (a, b) => a + b,
                                    0
                                  ) /
                                    100) *
                                  e.target.value,
                              });
                            }}
                            placeholder="Discount in percentage"
                            class="form-control offset-md-10 col-md-2"
                          />
                        </dd>
                      </dl>
                      <dl class="dlist-align">
                        <dt>Sub Total:</dt>
                        <dd class="text-right">
                          Rs.{this.state.totalamount.reduce((a, b) => a + b, 0)}{" "}
                        </dd>
                      </dl>
                      <dl class="dlist-align">
                        <dt>Total Discount:</dt>
                        <dd class="text-right">
                          Rs.{(parseInt(this.state.discountamount) + (
                              this.state.productdiscountearr.reduce(
                                (a, b) => a + b,
                                0
                              )))}
                        </dd>
                      </dl>
                      <dl class="dlist-align">
                        <dt>Total:</dt>
                        <dd class="text-right h4 b">
                          Rs.
                 
                          {(this.state.totalamount.reduce((a, b) => a + b, 0)) -
                            (parseInt(this.state.discountamount) + (
                              this.state.productdiscountearr.reduce(
                                (a, b) => a + b,
                                0
                              )))}
                        </dd>
                      </dl>
                      <div class="row">
                        <div class="col-md-6">
                          <a
                            href="#"
                            class="btn btn-danger btn-error btn-lg btn-block"
                          >
                            <i class="fa fa-times-circle"></i> Clear All
                          </a>
                        </div>
                        <div class="col-md-6">
                          <a
                            href="#"
                            onClick={this.openModalFinalSale}
                            class="btn btn-primary btn-lg btn-block"
                          >
                            <i class="fa fa-shopping-bag"></i> Charge
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Modal
          size="lg"
          show={this.state.isOpenCustomer}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addCustomer(e)} id="customerform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Name:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Customer Name"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Company:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company"
                    name="company"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Address:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Address"
                    name="address"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 1:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Phone Number 1"
                    name="phone1"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 2:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Phone Number"
                    name="phone2"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Credit Days:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Credit Days"
                    name="creditdays"
                  />
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-info">Save</button>{" "}
                <button onClick={this.closeModal} class="btn btn-danger">
                  Close
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.isOpenFinalizeSale}
          onHide={this.closeModalFinalSale}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Finalize Sale</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div class="row">
              <div class="col">
                <label>Received Amount *</label>
                <input
                  onChange={(e) => this.setState({ ramount: e.target.value })}
                  type="number"
                  required
                  name="ramount"
                  class="form-control"
                />
              </div>
              <div class="col">
                <label>Invoice Amount *</label>
                <input
                
                  value=
                    {(this.state.totalamount.reduce((a, b) => a + b, 0)) -
                      (parseInt(this.state.discountamount) + (
                        this.state.productdiscountearr.reduce(
                          (a, b) => a + b,
                          0
                        )))}
                  
                  type="number"
                  disabled
                  required
                  name="ramount"
                  class="form-control"
                />
              </div>
              <div class="col">
                <label>Change Amount</label>
                <input
                  type="number"
                  disabled
                  required
                  value={
                    parseInt(this.state.ramount) -(
                    (this.state.totalamount.reduce((a, b) => a + b, 0)) -
                    (parseInt(this.state.discountamount) + (
                      this.state.productdiscountearr.reduce(
                        (a, b) => a + b,
                        0
                      ))))
                  }
                  class="form-control"
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Hide Discount</label>
                <br />
                <Switch
                  onChange={this.handleSwitchChange}
                  checked={this.state.showdiscount}
                />
              </div>
              <div class="col">
                <label>Paid By *</label>
                <select
                  required
                  class="form-control"
                  onChange={(e) =>
                    this.setState({ paymenttype: e.target.value })
                  }
                >
                  <option value="cash">Cash</option>
                  <option value="credit">Credit</option>
                  <option value="cheque">Cheque</option>
                  <option value="banktransfer">Bank Transfer</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label>Invoice Remarks</label>
                <textarea
                  onChange={(e) => this.setState({ remarks: e.target.value })}
                  class="form-control"
                ></textarea>
              </div>
              <div class="col">
                <label>Payment Reference</label>
                <textarea
                  onChange={(e) =>
                    this.setState({ paymentreference: e.target.value })
                  }
                  placeholder="Cheque Number/Trasaction Number Etc"
                  class="form-control"
                ></textarea>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.closeModalFinalSale} variant="secondary">
              Close
            </Button>
            <Button onClick={this.savenPrint} variant="primary">
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default POS;
