import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";
class ProductList extends Component {
  state = {
    token: null,
    loading: false,
    products: null,
  };

  getProducts = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.products[0]) {
              this.setState({
                loading: false,
                products: res.data.products,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No products found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load products!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  deleteProduct = (id) => {
    Swal.fire({
      title: "Do you want to delete this product?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/product/" + id,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Product Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getProducts();
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };

  loadProducts = async (event) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product/search/" + event.target.value,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              products:res.data.products,
            });
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  loadProductsbySKU = async (event) => {
    if(!event.target.value){
      this.getProducts();
    }
    this.setState({
      products:null
    })
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product/searchbysku/" + event.target.value,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            this.setState({
              products:res.data.products,
            });
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getProducts();
  }

  render() {
    var ids = this.state.products ? (
      this.state.products.map((data, i) => {
        return (
          <tr>
            <td> {data.name}</td>
            <td> {data.price}</td>

            <td> {data.minqty}</td>

            <td>
              <button
                onClick={() =>
                  (window.location.pathname = `/vieweditproduct/${data.id}`)
                }
                class="btn btn-info"
              >
                View/Edit
              </button>
            </td>
            <td>
              <button
                onClick={() => this.deleteProduct(data.id)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left">
                <h1 class="text-center text-blue h2">Product List</h1>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
            <input type="text"
              class="form-control"
              placeholder="Search By Product"
              onChange={e=>this.loadProducts(e)}
              />
            </div>
            <div class="col">
              <input type="text"
              class="form-control"
              placeholder="Search By SKU"
              onChange={e=>this.loadProductsbySKU(e)}
              />
            </div>
          </div>
          <div class="pd-20 card-box mb-30">
            <table class="table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Product Price</th>
                  <th>Product Minimum Quantity</th>

                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{ids ? ids : <tr value="">No Data</tr>}</tbody>
            </table>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default ProductList;
