import React, { Component } from "react";
import axios from "axios";
import qs from "qs";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";

class ViewEditStore extends Component {
  state = {
    token: null,
    loading: false,
    pid: this.props.match.params.id,
    store: null,
    selectedStatus: null,
  };

  selectProjectStatus = (e) => {
    this.setState({
      selectedStatus: e.target.value,
    });
  };

  getStore = async () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL + "/store/single/" + this.state.pid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              store: response.data.store,
              selectedStatus: response.data.store.status,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  updateProject = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      let data = {
        id: this.state.pid,
        name: e.target.name.value,
        address: e.target.address.value,
        brnum: e.target.brnum.value,
        taxid: e.target.taxid.value,
        phone1: e.target.phone1.value,
        phone2: e.target.phone2.value,
        status: this.state.selectedStatus,
      };
      var config = {
        method: "PUT",
        url: process.env.REACT_APP_BASE_URL + "/store",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: qs.stringify(data),
      };

      axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({ loading: false });
            document.getElementById("projectform").reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Store Updated",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getProject();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured!", "error");
        });
    }
  };

  deleteProject = (id) => {
    Swal.fire({
      title: "Do you want to delete this store?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/store/" + this.state.pid,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Store Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                window.location.replace("/store/list");
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getStore();
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Store Details</h2>
              <p className="card-description">Edit Store Details</p>
              <hr />
              <form
                className="forms-sample"
                onSubmit={(e) => this.updateProject(e)}
                id="projectform"
              >
                <div className="form-group">
                  <label htmlFor="custName">Store Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    required
                    defaultValue={this.state.store?.name}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    required
                    defaultValue={this.state.store?.address}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone1">Contact Number</label>
                  <input
                    type="number"
                    className="form-control"
                    name="phone1"
                    required
                    defaultValue={this.state.store?.phone1}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone2">Secondary Contact Number</label>
                  <input
                    type="number"
                    className="form-control"
                    name="phone2"
                    defaultValue={this.state.store?.phone2}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone2">Business Registration</label>
                  <input
                    type="text"
                    className="form-control"
                    name="brnum"
                    defaultValue={this.state.store?.brnum}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone2">Tax Registration</label>
                  <input
                    type="text"
                    className="form-control"
                    name="taxid"
                    defaultValue={this.state.store?.taxid}
                  />
                </div>

                <div class="form-group">
                  <label>Project Status</label>
                  <div class="">
                    <select
                      name="status"
                      value={this.state.selectedStatus}
                      onChange={this.selectProjectStatus}
                      class="form-control"
                      required
                    >
                      <option value="">Select</option>
                      <option value="1">Active</option>
                      <option value="0">Deactive</option>
                    </select>
                  </div>
                </div>

                <button type="submit" className="btn btn-success mr-2">
                  Update
                </button>
                <button type="button" onClick={() => this.deleteProject()} className="btn btn-danger">
                  Delete
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default ViewEditStore;
