import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";
import AsyncSelect from "react-select/async";

class NewCategory extends Component {
  state = {
    token: null,
    loading: false,
    subcatid: null,
    categories:null,
    subcategories:null
  };

  loadOptions = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/category/search/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.category;
            callback(json.map((i) => ({ label: i.catname, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  addCategory = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      let data = new FormData();
      data.append("catname", e.target.catname.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/category/new",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({ loading: false });
            document.getElementById("catform").reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Category Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getCategories();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured", "error");
        });
    }
  };

  addSubCategory = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      let data = new FormData();
      data.append("subcatname", e.target.subcatname.value);
      data.append("catid", this.state.subcatid);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/subcategory/new",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({ loading: false });
            document.getElementById("subcatform").reset();
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Sub Category Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getSubCategories();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured", "error");
        });
    }
  };

  getCategories = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/category/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.categories) {
              this.setState({
                loading: false,
                categories: res.data.categories,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });

          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getSubCategories =  (id) => {
    this.setState({
      loading: true,
      subcategories:null
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/subcategory/related/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

       axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.subcategories[0]) {
              this.setState({
                loading: false,
                subcategories: res.data.subcategories,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });

          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  deleteCategory = (id) => {
    Swal.fire({
      title: "Do you want to delete this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/category/" + id,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                  categories:null
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Category Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getCategories()
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };

  deleteSubcategory = (id) => {
    Swal.fire({
      title: "Do you want to delete this sub category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/subcategory/" + id,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                  subcategories: null,
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Sub Category Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getSubCategories()
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getCategories();
  }

  render() {
    let ids = this.state.categories ? (
      this.state.categories.map((data, i) => {
       
        return (
          <tr>
          
            <td> {data.catname}</td>
            <td> {data.created_at}</td>
            <td>
              <button
                onClick={() => this.getSubCategories(data.id)}
                class="btn btn-info"
              >
                Sub Categories
              </button>
            </td>
            <td>
              <button
                onClick={() => this.deleteCategory(data.id)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
         
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    let subids = this.state.subcategories ? (
      this.state.subcategories.map((data, i) => {
       
        return (
          <tr>
          
            <td> {data.subcatname}</td>
            <td> {data.catname}</td>
            <td> {data.created_at}</td>
            <td>
              <button
                onClick={() => this.deleteSubcategory(data.id)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
         
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="row">
            <div class="col-lg-6">
              <div class="pd-20 card-box mb-30">
                <div class="clearfix">
                  <div class="pull-left">
                    <h1 class="text-center text-blue h2">New Category</h1>
                  </div>
                </div>
                <hr />
                <form onSubmit={(e) => this.addCategory(e)} id="catform">
                  <div class="form-group row">
                    <label class="col-sm-6 col-md-2 col-form-label">
                      Category Name:
                    </label>
                    <div class="col-sm-6 col-md-6">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Category Name"
                        name="catname"
                        required
                      />
                    </div>
                    <button class="btn btn-primary " type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="pd-20 card-box mb-30">
                <div class="clearfix">
                  <div class="pull-left">
                    <h1 class="text-center text-blue h2">New Sub Category</h1>
                  </div>
                </div>
                <hr />
                <form onSubmit={(e) => this.addSubCategory(e)} id="subcatform">
                  <div class="form-group row">
                    <label class="col-sm-12 col-md-2 col-form-label">
                      Select Main Category:
                    </label>
                    <div class="col-sm-12 col-md-10">
                      <AsyncSelect
                        onChange={(opt) => {
                          if (opt) {
                            this.setState({ subcatid: opt.value });
                          } else {
                            this.setState({ subcatid: null });
                          }
                        }}
                        placeholder={"Search category"}
                        loadOptions={this.loadOptions}
                        isClearable
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-6 col-md-2 col-form-label">
                      Sub Category Name:
                    </label>
                    <div class="col-sm-6 col-md-6">
                      <input
                        class="form-control"
                        type="text"
                        placeholder="Category Name"
                        name="subcatname"
                        required
                      />
                    </div>
                    <button class="btn btn-primary " type="submit">
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="pd-20 card-box mb-30">
              <table class="table">
              <thead>
                <tr>
                  
                  <th>Category</th>
                  <th>Created At</th>
      
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{ids ? ids : <tr value="">No Data</tr>}</tbody>
            </table>
            </div>
            </div>
            <div class="col-lg-6">
              <div class="pd-20 card-box mb-30">
              <table class="table">
              <thead>
                <tr>
                  
                  <th>Sub Category</th>
                  <th>Main Category</th>
                  <th>Created At</th>
      
                  <th></th>
                </tr>
              </thead>
              <tbody>{subids ? subids : <tr value="">No Data</tr>}</tbody>
            </table>
            </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}
export default NewCategory;
