import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";

class Login extends Component {
  state = {
    username: null,
    password: null,
    status: null,
    loading: false,
  };

  loginHandler = async (event) => {
    // console.log(event.target)
    event.preventDefault();

    this.setState({ loading: true });

    let email = event.target.email.value;
    let pw = event.target.pw.value;

    if (!email || !pw) {
      this.setState({ loading: false });
      Swal.fire("Oops...", "Please fill required", "error");
    } else {
      this.setState({ loading: true });
      var data = new FormData();
      data.append("email", email);
      data.append("password", pw);
    
      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL+"/login",
        data: data,
      };

      await axios(config)
        .then((response)=> {
         
          if (response.data.success === true) {
            
            sessionStorage.setItem(
              "userToken",
              JSON.stringify(response.data.token.token)
            );
            sessionStorage.setItem(
              "role",
              JSON.stringify(response.data.role)
            );
           
            this.setState({ loading: false });
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Login successful, redirecting..",
              showConfirmButton: false,
              timer: 1500,
            });
            window.location.href = "/dashboard";
          }
        })
        .catch( (error) =>{
          this.setState({ loading: false });
          Swal.fire("Oops...", "Invalid credentials", "error");
        });
    }
  };

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="login-wrap d-flex align-items-center flex-wrap justify-content-center">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 col-lg-7">
              <h1>Dilruk Motors</h1>
              </div>
              <div class="col-md-6 col-lg-5">
                <div class="login-box bg-white box-shadow border-radius-10">
                  <div class="login-title">
                    <h2 class="text-center text-primary">
                      Login
                    </h2>
                  </div>
                  <form onSubmit={(e) => this.loginHandler(e)}>
                    <div class="input-group custom">
                      <input
                        type="email"
                        class="form-control form-control-lg"
                        name="email"
                        placeholder="E mail"
                        required
                      />
                      <div class="input-group-append custom">
                        <span class="input-group-text">
                          <i class="icon-copy dw dw-user1"></i>
                        </span>
                      </div>
                    </div>
                    <div class="input-group custom">
                      <input
                        type="password"
                        name="pw"
                        class="form-control form-control-lg"
                        placeholder="**********"
                        required
                      />
                      <div class="input-group-append custom">
                        <span class="input-group-text">
                          <i class="dw dw-padlock1"></i>
                        </span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <div class="input-group mb-0">
                          <button
                            class="btn btn-primary btn-lg btn-block"
                            type="submit"
                          >
                            Sign In
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default Login;
