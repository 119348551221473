import React, { Component } from "react";

class Sidebar extends Component {
  render() {
    if (window.location.pathname === "/") {
      return null;
    }
    return (
      <div class="left-side-bar">
        <div class="brand-logo">
          <a href="/dashboard">
           <h4 class='text-white'>Dilruk Motors</h4>
          </a>
          <div class="close-sidebar" data-toggle="left-sidebar-close">
            <i class="ion-close-round"></i>
          </div>
        </div>
        <div class="menu-block customscroll">
          <div class="sidebar-menu">
            <ul id="accordion-menu">
            <li>
                <a href="/dashboard" class="dropdown-toggle no-arrow">
                  <span class="micon dw dw-bar-chart-1"></span>
                  <span class="mtext">Dashboard</span>
                </a>
              </li>
              <li class="dropdown">
                <a href="javascript:;" class="dropdown-toggle">
                  <span class="micon dw dw-groceries-store"></span>
                  <span class="mtext">Store Management</span>
                </a>
                <ul class="submenu">
                  <li>
                    <a href="/store/new">New Store</a>
                  </li>
                  <li>
                    <a href="/store/list">Store List</a>
                  </li>
            
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:;" class="dropdown-toggle">
                  <span class="micon fa fa-product-hunt"></span>
                  <span class="mtext">Product Management</span>
                </a>
                <ul class="submenu">
                <li>
                    <a href="/newcategory">New Category</a>
                  </li>
                  <li>
                    <a href="/addproduct">Add a Product</a>
                  </li>
                  <li>
                    <a href="/productlist">Product List</a>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:;" class="dropdown-toggle">
                  <span class="micon dw dw-package"></span>
                  <span class="mtext">Supplier Management</span>
                </a>
                <ul class="submenu">
                  <li>
                    <a href="/supplierlist">Supplier List</a>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:;" class="dropdown-toggle">
                  <span class="micon dw dw-food-cart"></span>
                  <span class="mtext">Inventory Management</span>
                </a>
                <ul class="submenu">
                  <li>
                    <a href="/grn/new">New GRN</a>
                  </li>
                  <li>
                    <a href="/grn/list">GRN List</a>
                  </li>
                  <li>
                    <a href="#">Stock Inter-Transfer</a>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:;" class="dropdown-toggle">
                  <span class="micon dw dw-add-user"></span>
                  <span class="mtext">Customer Management</span>
                </a>
                <ul class="submenu">
         
                  <li>
                    <a href="/customer/list">Customer List</a>
                  </li>
        
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:;" class="dropdown-toggle">
                  <span class="micon dw dw-invoice"></span>
                  <span class="mtext">Invoice Management</span>
                </a>
                <ul class="submenu">
         
                  <li>
                    <a href="/invoice/list">Invoice List</a>
                  </li>
        
                </ul>
              </li>
              <li class="dropdown">
                <a href="javascript:;" class="dropdown-toggle">
                  <span class="micon dw dw-bar-chart"></span>
                  <span class="mtext">Reports</span>
                </a>
                <ul class="submenu">
         
                  <li>
                    <a href="/report/allproductssold">Sold Products List</a>
                  </li>
                  <li>
                    <a href="/report/monthlysales">Monthly Sales</a>
                  </li>
        
                </ul>
              </li>
            
             
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
