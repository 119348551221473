import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import loader from "../../Resources/loader.gif";

class NewGrn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      status: null,
      loading: false,
      suppliers: null,
      stores: null,
      products: null,
      values: [],
      productarr: [],
      qtyarr: [],
      totalarr: [],
      productlabel: [],
      unitpricearr: [],
      supplierid: null,
      storeid: null,
      grnid: null,
   
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  createUI() {
    return this.state.values.map((qty, i) => (
      <div key={i}>
        <tr class="d-flex">
          <td class="col-4">
            <div class="form-group">
              <AsyncSelect
                onChange={(opt) => {
                  if (opt) {
                    this.handleProductChange(i, opt);
                  }
                }}
                placeholder={"Search Products"}
                loadOptions={this.loadProducts}
                value={{
                  value: this.state.productarr[i],
                  label: this.state.productlabel[i],
                }}
                isClearable
                required
              />
            </div>
          </td>
          <td class="col-2">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                value={this.state.unitpricearr[i] || ""}
                disabled
              />
            </div>
          </td>
          <td class="col-2">
            <div class="form-group">
              <input
                class="form-control"
                value={this.state.qtyarr[i] || ""}
                onChange={this.handleQtyChange.bind(this, i)}
                type="number"
                name="qty"
              />
            </div>
          </td>
          <td class="col-3">
            <div class="form-group">
              <input
                class="form-control"
                type="number"
                value={this.state.totalarr[i] || ""}
                onChange={this.handleTotalChange.bind(this, i)}
                name="totalcost"
              />
            </div>
          </td>
          <td class="col-1">
            <input
              class="btn btn-danger"
              type="button"
              value="x"
              onClick={this.removeClick.bind(this, i)}
            />
          </td>
        </tr>
      </div>
    ));
  }

  handleSupplier = (e) => {
    this.setState({
      supplierid: e.target.value,
    });
  };
  handleStore = (e) => {
    this.setState({
      storeid: e.target.value,
    });
  };

  handleProductChange(i, opt) {
    let productarr = [...this.state.productarr];
    let productlabel = [...this.state.productlabel];
    productarr[i] = opt.value;
    productlabel[i] = opt.label;
    this.setState({
      productarr,
      productlabel,
    });
  }

  handleQtyChange(i, event) {
    let qtyarr = [...this.state.qtyarr];
    qtyarr[i] = event.target.value;
    this.setState({ qtyarr });
  }

  handleTotalChange(i, event) {
    let totalarr = [...this.state.totalarr];
    totalarr[i] = event.target.value;
    let unitpricearr = [...this.state.unitpricearr];
    unitpricearr[i] = totalarr[i] / this.state.qtyarr[i];
    this.setState({
      totalarr,
      unitpricearr,
    });
  }

  addClick() {
    this.setState((prevState) => ({ values: [...prevState.values, ""] }));
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    let productarr = [...this.state.productarr];
    productarr.splice(i, 1);
    let productlabel = [...this.state.productlabel];
    productlabel.splice(i, 1);
    let qtyarr = [...this.state.qtyarr];
    qtyarr.splice(i, 1);
    let totalarr = [...this.state.totalarr];
    totalarr.splice(i, 1);
    this.setState({
      values,
      productarr,
      productlabel,
      qtyarr,
      totalarr,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.supplierid == null && this.state.storeid == null) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Select supplier & store!",
      });
    } else {
      if (
        this.state.productarr.length > 0 &&
        this.state.qtyarr.length > 0 &&
        this.state.qtyarr.length > 0
      ) {
        this.setState({
          loading: true,
        });
        var data = new FormData();
        data.append("supid", this.state.supplierid);
        data.append("storeid", this.state.storeid);
        data.append(
          "supinvoiceid",
          document.getElementsByName("supinvoice").value
        );

        var config = {
          method: "post",
          url: process.env.REACT_APP_BASE_URL + "/grn",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.success === true) {
              this.setState({
                grnid: response.data.grnid,
              });
              for (let index = 0; index < this.state.values.length; index++) {
                
                var data = new FormData();
                data.append("grnid", this.state.grnid);
                data.append("productid", this.state.productarr[index]);
                data.append("unitprice", this.state.unitpricearr[index]);
                data.append("qty", this.state.qtyarr[index]);
                data.append("price", this.state.totalarr[index]);

                var config = {
                  method: "post",
                  url: process.env.REACT_APP_BASE_URL + "/grnitem",
                  headers: {
                    Authorization: `Bearer ${this.state.token}`,
                  },
                  data: data,
                };

                axios(config)
                  .then( (response)=> {
                    console.log(JSON.stringify(response.data));
                  })
                  .catch( (error) =>{
                    console.log(error);
                  });
              }
              this.setState({
                loading: false,
                values: [],
                productarr: [],
                qtyarr: [],
                totalarr: [],
                productlabel: [],
                unitpricearr: [],
                supplierid: null,
                storeid: null,
              });
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "GRN Added",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            Swal.fire("Oops...", "Error occured", "error");
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Add & Fill Product Rows!",
        });
      }
    }
  }

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSuppliers();
    this.getStores();
  };

  loadProducts = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product/search/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.products;
            callback(json.map((i) => ({ label: i.name, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  getSuppliers = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/supplier",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.suppliers[0]) {
              this.setState({
                loading: false,
                suppliers: res.data.suppliers,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No suppliers found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load suppliers!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getStores = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/store",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.stores[0]) {
              this.setState({
                loading: false,
                stores: res.data.stores,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No stores found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load stores!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getProducts = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.products[0]) {
              this.setState({
                loading: false,
                products: res.data.products,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No products found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load products!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    let supplierselect = this.state.suppliers ? (
      this.state.suppliers.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    let storeselect = this.state.stores ? (
      this.state.stores.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left d-flex w-100">
                <h1 class="text-center text-blue h2">
                  Create Good Received Note
                </h1>
                <button
                  type="button"
                  class="ml-auto btn btn-warning"
                  onClick={this.addClick.bind(this)}
                >
                  Add a Product Row
                </button>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label>Supplier Invoice</label>

                  <input
                    class="form-control"
                    type="number"
                    placeholder="Supplier Invoice"
                    name="supinvoice"
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label>Supplier *</label>

                  <select
                    onChange={(e) => {
                      this.handleSupplier(e);
                    }}
                    name="supplierid"
                    class="form-control"
                    required
                  >
                    <option value="">Select</option>
                    {supplierselect ? (
                      supplierselect
                    ) : (
                      <option value="">No Data</option>
                    )}
                  </select>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label>Target Store *</label>

                  <select
                    onChange={(e) => {
                      this.handleStore(e);
                    }}
                    name="storeid"
                    class="form-control"
                    required
                  >
                    <option value="">Select</option>
                    {storeselect ? (
                      storeselect
                    ) : (
                      <option value="">No Data</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div className="col-lg-12">
                <p>
                  * Note:Please add all the items and Click Finish to Proceed.
                </p>
                <form onSubmit={this.handleSubmit}>
                  <table className="table table-bordered">
                    <thead className="thead table-primary">
                      <tr class="d-flex">
                        <th class="col-4" scope="text-center gray-ish">
                          Product
                        </th>
                        <th class="col-2" scope="text-center gray-ish">
                          Unit Price
                        </th>
                        <th class="col-2" scope="text-center gray-ish">
                          Quantity
                        </th>
                        <th class="col-3" scope="text-center gray-ish">
                          Total Cost
                        </th>
                        <th class="col-1"> </th>
                      </tr>
                    </thead>

                    <tbody>{this.createUI()}</tbody>
                  </table>
                  <input class="btn btn-success" type="submit" value="Submit" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default NewGrn;
