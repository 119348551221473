import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import { Modal, Table, Dropdown } from "react-bootstrap";
import loader from "../../Resources/loader.gif";
import AsyncSelect from "react-select/async";
class InvoiceList extends Component {
  state = {
    token: null,
    loading: false,
    invoices: null,
    payments: null,
    isOpen: false,
    invoiceid: null,
    isOpenPayment: null,
    selectedcustid:null,
    customerlabel:null
  };

 handleCustomerChange(opt) {
    this.setState({
      customerlabel: opt.label,
      selectedcustid: opt.value,
    });

     this.searchInvoicebyCustomer(opt.value);
  }

  loadCustomer = async (inputText, callback) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/search/" + inputText,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            const json = res.data.customers;
            callback(json.map((i) => ({ label: i.phone1, value: i.id })));
          }
        })
        .catch((error) => {});
    } else {
      this.setState({
        loading: false,
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Authentication Error!",
      });
    }
  };

  openModal = (id) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/payment/invoice/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config).then((res) => {
        if (res.data.success) {
          if (res.data.payments[0]) {
            this.setState({
              loading: false,
              payments: res.data.payments,
              isOpen: true,
            });
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "No payments!",
            });
          }
        } else {
          this.setState({
            loading: false,
          });

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to Load Payments!",
          });
        }
      });
    }
  };

  closeModal = () => this.setState({ isOpen: false });

  openModalPayment = (id) =>
    this.setState({
      isOpenPayment: true,
      invoiceid: id,
    });
  closeModalPayment = () => this.setState({ isOpenPayment: false });

  addPayment = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("invoiceid", this.state.invoiceid);
      data.append("paymenttype", e.target.paymenttype.value);
      data.append("reference", e.target.remarks.value);
      data.append("amount", e.target.amount.value);
      data.append("billnum", "0");

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/payment",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpenPayment: false,
              invoiceid: null,
            });
            document.getElementById("paymentform").reset();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Payment Added",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            invoiceid: null,
          });
          Swal.fire("Oops...", "Error occured", "error");
        });
    }
  };

  getInvoices = async () => {
    this.setState({
      loading: false,
      invoices: null,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/invoice/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.invoices[0]) {
              this.setState({
                loading: false,
                invoices: res.data.invoices,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No invoices found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load invoices!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getCreditInvoices = async () => {
    this.setState({
      loading: false,
      invoices: null,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/invoice/credit/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.invoices[0]) {
              this.setState({
                loading: false,
                invoices: res.data.invoices,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No invoices found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load invoices!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  searchInvoice = async () => {
    this.setState({
      loading: false,
      invoices: null,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL +
          "/invoice/search/" +
          this.state.invoiceid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.invoices[0]) {
              this.setState({
                loading: false,
                invoices: res.data.invoices,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No invoices found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load invoices!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  
  searchInvoicebyCustomer = async (custid) => {
    this.setState({
      loading: false,
      invoices: null,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL +
          "/invoice/searchbycustomer/" +
          custid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.invoices[0]) {
              this.setState({
                loading: false,
                invoices: res.data.invoices,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No invoices found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load invoices!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  deleteInvoice = (id) => {
    Swal.fire({
      title: "Do you want to delete this customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/invoice/" + id,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getInvoices();
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };
  deletePayment = (id) => {
    Swal.fire({
      title: "Do you want to delete this payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/payment/" + id,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.closeModal();
                this.getInvoices();
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getInvoices();
  }

  render() {
    var ids = this.state.invoices ? (
      this.state.invoices.map((data, i) => {
        if (
          data.paymenttype == "credit" &&
          parseInt(data.total) - parseInt(data.paidtotal) == 0
        ) {
          return (
            <tr class="table-success">
              <td> {data.invoiceid}</td>
              <td> {data.created_at}</td>
              <td> {data.name}</td>
              <td> {data.total}</td>
              <td> {data.discount}</td>
              <td>
                <span class="badge badge-light">{data.paymenttype}</span>
              </td>
              <td> {parseInt(data.total) - parseInt(data.paidtotal)}</td>
              <td>
                <button
                  onClick={() =>
                    (window.location.pathname = `/printinvoice/${data.invoiceid}`)
                  }
                  class="btn btn-primary"
                >
                  Print Invoice
                </button>
              </td>

              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                  >
                    Quick Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => this.openModalPayment(data.invoiceid)}
                    >
                      Add a Payment
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => this.openModal(data.invoiceid)}
                    >
                      View Payments
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => this.deleteInvoice(data.invoiceid)}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          );
        } else if (data.paymenttype == "credit") {
          return (
            <tr class="table-danger">
              <td> {data.invoiceid}</td>
              <td> {data.created_at}</td>

              <td> {data.name}</td>
              <td> {data.total}</td>
              <td> {data.discount}</td>
              <td>
                <span class="badge badge-light">{data.paymenttype}</span>
              </td>
              <td> {parseInt(data.total) - parseInt(data.paidtotal)}</td>
              <td>
                <button
                  onClick={() =>
                    (window.location.pathname = `/printinvoice/${data.invoiceid}`)
                  }
                  class="btn btn-primary"
                >
                  Print Invoice
                </button>
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                  >
                    Quick Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => this.openModalPayment(data.invoiceid)}
                    >
                      Add a Payment
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => this.openModal(data.invoiceid)}
                    >
                      View Payments
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => this.deleteInvoice(data.invoiceid)}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          );
        } else {
          return (
            <tr class="table-secondary">
              <td> {data.invoiceid}</td>
              <td> {data.created_at}</td>

              <td> {data.name}</td>
              <td> {data.total}</td>
              <td> {data.discount}</td>
              <td>
                <span class="badge badge-light">{data.paymenttype}</span>
              </td>
              <td> 0</td>
              <td>
                <button
                  onClick={() =>
                    (window.location.pathname = `/printinvoice/${data.invoiceid}`)
                  }
                  class="btn btn-primary"
                >
                  Print Invoice
                </button>
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                  >
                    Quick Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => this.openModalPayment(data.invoiceid)}
                    >
                      Add a Payment
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => this.openModal(data.invoiceid)}
                    >
                      View Payments
                    </Dropdown.Item>
                    <Dropdown.Divider />

                    <Dropdown.Item
                      onClick={() => this.deleteInvoice(data.invoiceid)}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          );
        }
      })
    ) : (
      <tr>No Data</tr>
    );

    var payments = this.state.payments ? (
      this.state.payments.map((data, i) => {
        return (
          <tr>
            <td> {data.paiddate}</td>

            <td> {data.amount}</td>
            <td> {data.paymenttype}</td>
            <td> {data.reference}</td>
            <td>
              {" "}
              <span class="badge badge-info">{data.name}</span>
            </td>
            <td>
              {" "}
              <button
                onClick={() => this.deletePayment(data.id)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left d-flex w-100">
                <h1 class="text-center text-blue h2">Invoice List</h1>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="input-group col">
                <input
                  type="text"
                  name="invoicenumber"
                  class="form-control"
                  placeholder="Invoice Number"
                  onChange={(e) => this.setState({ invoiceid: e.target.value })}
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    onClick={this.searchInvoice}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div class="col">
                <AsyncSelect
                  placeholder={"Customer"}
                  onChange={(opt) => {
                    if (opt) {
                      this.handleCustomerChange(opt);
                    }
                  }}
                  value={{
                    value: "Search customer by phone",
                    label: "Search customer by phone",
                  }}
                  loadOptions={this.loadCustomer}
                  isClearable
                  required
                />
              </div>

              <div class="col">
                <button
                  class="btn btn-block btn-danger"
                  onClick={this.getCreditInvoices}
                >
                  Credit Invoices
                </button>
              </div>
              <div class="col">
                <button
                  class="btn btn-block btn-info"
                  onClick={this.getInvoices}
                >
                  All Invoices
                </button>
              </div>
            </div>
          </div>
          <div class="pd-20 card-box mb-30">
            <table class="table">
              <thead>
                <tr>
                  <th>Invoice No</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Total</th>
                  <th>Discount</th>
                  <th>Payment Type</th>
                  <th>Balance Amount</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{ids ? ids : <tr value="">No Data</tr>}</tbody>
            </table>
          </div>
        </div>
        <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Payments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Payment Type</th>
                  <th>Reference</th>
                  <th>User Charged</th>
                </tr>
              </thead>
              <tbody>{payments ? payments : <tr value="">No Data</tr>}</tbody>
            </Table>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.isOpenPayment}
          onHide={this.closeModalPayment}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addPayment(e)} id="paymentform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Amount *:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Amount"
                    name="amount"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Payment Type *:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select required class="form-control" name="paymenttype">
                    <option value="cash">Cash</option>
                    <option value="cheque">Cheque</option>
                    <option value="banktransfer">Bank Transfer</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Payment Remarks *:
                </label>
                <div class="col-sm-12 col-md-10">
                  <textarea
                    name="remarks"
                    class="form-control"
                    placeholder="Cheque Number/Trasaction Number Etc"
                  ></textarea>
                </div>
              </div>
              <div class="form-group row">
                <input
                  type="submit"
                  class="btn btn-block btn-warning"
                  value="Submit"
                />
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default InvoiceList;
