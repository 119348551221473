import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import loader from "../../Resources/loader.gif";

class SupplierList extends Component {
  state = {
    token: null,
    loading: false,
    supplier: null,
    isOpenSupplier: false,
  };

  openModal = () => this.setState({ isOpenSupplier: true });
  closeModal = () => this.setState({ isOpenSupplier: false });

  getSuppliers = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/supplier",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.suppliers[0]) {
              this.setState({
                loading: false,
                suppliers: res.data.suppliers,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No suppliers found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load suppliers!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  addSupplier = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("name", e.target.name.value);
      data.append("company", e.target.company.value);
      data.append("address", e.target.address.value);
      data.append("phone1", e.target.phone1.value);
      data.append("phone2", e.target.phone2.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/supplier",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
          
            this.setState({
              loading: false,
              isOpenSupplier: false,
            });
            document.getElementById("supplierform").reset();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Supplier Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getSuppliers();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured", "error");
        });
    }
  };

  deleteSupplier = (id) => {
    Swal.fire({
      title: "Do you want to delete this supplier?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/supplier/" + id,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Supplier Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getSuppliers();
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };

  getSupplier = async (id) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/supplier/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
            
            this.setState({
              supplier: response.data.supplier,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSuppliers();
  }

  render() {
    var ids = this.state.suppliers ? (
      this.state.suppliers.map((data, i) => {
       
        return (
          <tr>
            
            <td> {data.name}</td>
            <td> {data.address}</td>
          
            <td> {data.phone1}</td>
           
            <td><button onClick={()=>window.location.pathname =`/vieweditsupplier/${data.id}`} class="btn btn-info">View/Edit</button></td>
            <td>
              <button
                onClick={() => this.deleteSupplier(data.id)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
         
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left d-flex w-100">
                <h1 class="text-center text-blue h2">Suppliers List</h1>
                <button type="button" class="ml-auto btn btn-warning"  onClick={this.openModal}>Add a Supplier</button>
              </div>
            </div>
          </div>
          <div class="pd-20 card-box mb-30">
            <table class="table">
              <thead>
                <tr>
                
                  <th>Name</th>
                  <th>Address</th>
                  <th>Phone</th>
      
      
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{ids ? ids : <tr value="">No Data</tr>}</tbody>
            </table>
          </div>
        </div>
        <Modal size="lg" show={this.state.isOpenSupplier} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Supplier</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addSupplier(e)} id="supplierform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Name:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Supplier Name"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Company:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company"
                    name="company"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Address:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Address"
                    name="address"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Phone 1:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Phone Number 1"
                    name="phone1"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 2:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Phone Number"
                    name="phone2"
                    
                  />
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-info">Save</button>{" "}
                <button onClick={this.closeModal} class="btn btn-danger">
                  Close
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default SupplierList;