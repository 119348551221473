import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import loader from "../../Resources/loader.gif";
import { Bar } from "react-chartjs-2";

const currentyear = new Date().getFullYear();
const today = new Date();
const month = String(today.getMonth() + 1).padStart(2, "0");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const startdate = new Date("January 01," + currentyear);

function monthDiff(dateFrom, dateTo) {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
}

const nummonths = monthDiff(
  new Date(currentyear, "01"),
  new Date(currentyear, month)
);

let arrmonths = [];

for (let index = 0; index < nummonths+1; index++) {
  arrmonths[index] = monthNames[index];
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: false,
        },
      },
    ],
  },
};

class MonthlySales extends Component {
  state = {
    token: null,
    loading: false,
    sales: [],
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSalesNumbers();
  }

  getSalesNumbers = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      let salesvalues = [];
      for (let index = 0; index < nummonths+1; index++) {
        var config = {
          method: "get",
          url:
            process.env.REACT_APP_BASE_URL +
            "/reports/monthlysales/" +
            currentyear +
            "&" + (parseInt(index)+1),
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };

        await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.monthlysales) {
              this.setState({
                loading: false,
              });
              salesvalues[index]=res.data.monthlysales
              console.log(res.data.monthlysales);
            } 
          } else {
            this.setState({
              loading: false,
            });

          }
        })
        .catch((error) => {
          console.log(error);
        });

        
      }

      this.setState({
        sales:salesvalues
      })

    
    }
  };

  render() {
    const data = {
      labels: arrmonths,
      datasets: [
        {
          label: "# of Sales",
          data: this.state.sales,
          backgroundColor: ["rgba(255, 99, 132, 0.2)"],
          borderColor: ["rgba(255, 99, 132, 1)"],
          borderWidth: 1,
        },
      ],
    };
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="main-container">
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left d-flex w-100">
                <h1 class="text-center text-blue h2">
                  Monthly Sales from {currentyear}-01 to {currentyear}-{month}{" "}
                </h1>
              </div>
            </div>
          </div>
          <div class="pd-20 card-box mb-30">
            <Bar data={data} options={options} />
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default MonthlySales;
