import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./protectedRoute";

import Login from "./Components/User/login";
import changePassword from "./Components/User/changepassword";
import Dashboard from "./Components/dashboard";
import NavBar from "./Components/Partials/navbar";
import Footer from "./Components/Partials/footer";
import Sidebar from "./Components/Partials/sidebar";

//Product
import NewCategory from "./Components/Category/newcategory";
import NewProduct from "./Components/Product/addproduct";
import ProductList from "./Components/Product/productlist";
import ViewEditProduct from "./Components/Product/vieweditproduct";

//Supplier
import SupplierList from "./Components/Supplier/supplierlist";
import ViewEditSupplier from "./Components/Supplier/vieweditsupplier";

//Store
import NewStore from "./Components/Store/new";
import StoreList from "./Components/Store/list";
import ViewEditStore from "./Components/Store/viewedit";

//GRN
import NewGrn from "./Components/GRN/new";
import GrnList from "./Components/GRN/grnlist";

//POS
import POS from "./Components/pos";

//Customer
import CustomerList from "./Components/customer/customerlist";

//Invoice
import PrintInvoice from "./Components/Invoice/printinvoice";
import InvoiceList from "./Components/Invoice/invoicelist";

//Reports
import AllSoldProducts from "./Components/Reports/allsoldproducts";
import MonthlySales from "./Components/Reports/monthlysales";

class Routes extends Component {
  render() {
    return (
      <Router>
        <ProtectedRoute path="/pos" exact strict component={POS} />
        <NavBar />
        <Sidebar />
        {/* <Footer /> */}
        <Switch>
          <Route path="/" exact strict component={Login} />

          {/* invoicelist */}
          <ProtectedRoute
            path="/printinvoice/:id"
            exact
            strict
            component={PrintInvoice}
          />
          <ProtectedRoute
            path="/invoice/list"
            exact
            strict
            component={InvoiceList}
          />

          <ProtectedRoute
            path="/dashboard"
            exact
            strict
            component={Dashboard}
          />
          <ProtectedRoute
            path="/changepassword"
            exact
            strict
            component={changePassword}
          />

          <ProtectedRoute path="/store/new" exact strict component={NewStore} />
          <ProtectedRoute
            path="/store/list"
            exact
            strict
            component={StoreList}
          />
          <ProtectedRoute
            path="/store/update/:id"
            exact
            strict
            component={ViewEditStore}
          />

          <ProtectedRoute
            path="/newcategory"
            exact
            strict
            component={NewCategory}
          />

          <ProtectedRoute
            path="/addproduct"
            exact
            strict
            component={NewProduct}
          />
          <ProtectedRoute
            path="/productlist"
            exact
            strict
            component={ProductList}
          />

          <ProtectedRoute
            path="/vieweditproduct/:id"
            exact
            strict
            component={ViewEditProduct}
          />

          <ProtectedRoute
            path="/supplierlist"
            exact
            strict
            component={SupplierList}
          />
          <ProtectedRoute
            path="/vieweditsupplier/:id"
            exact
            strict
            component={ViewEditSupplier}
          />
          {/* GRN */}
          <ProtectedRoute path="/grn/new" exact strict component={NewGrn} />
          <ProtectedRoute path="/grn/list" exact strict component={GrnList} />

          {/* Customer */}
          <ProtectedRoute
            path="/customer/list"
            exact
            strict
            component={CustomerList}
          />

          {/* Reports */}
          <ProtectedRoute
            path="/report/allproductssold"
            exact
            strict
            component={AllSoldProducts}
          />
          <ProtectedRoute
            path="/report/monthlysales"
            exact
            strict
            component={MonthlySales}
          />
        </Switch>
      </Router>
    );
  }
}

export default Routes;
